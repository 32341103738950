<template>
  <nav class="henry-nav navbar navbar-dark bg-dark bg-opacity-25">

    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <a class="navbar-brand" href="#">Oh!Henry...</a>

      
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item" v-for="item in this.menuItems">
            <router-link v-if="item.location" class="nav-link" :to="item.location">{{item.text}}</router-link>
            <a v-if="item.link" class="nav-link" :href="item.link">{{item.text}}</a>
          </li>
          <li class="nav-item">
            <a href="https://store.ohhenryleather.com/" class="nav-link">Store</a>
          </li>
        </ul>
      </div>
      
    </div>
  </nav>
</template>

<script>
export default {
name: "Menu.vue",
  setup(){
    return {
      menuItems: [
        {text: 'Harnesses', location: '/harnesses'},
        // {text: 'Tops', location: '/'},
        // {text: 'Chains', location: '/'},
        // {text: 'Bags', location: '/'},
        {text: 'Cuffs & Collars', location: '/cuffs_n_collars'},
        // {text: 'Lilith Collection', location: '/'},
        {text: 'The Year of the Dragon Collection', link: 'https://store.ohhenryleather.com/The-Year-of-The-Dragon-Collection-c164175253'},

        {text: 'Who the hell is Henry?', location: '/who_is_henry'}
      ]
    }
  }
}
</script>

<style scoped lang="scss">


.henry-nav {
  .nav-item .nav-link {
    color: inherit;
  }

  padding-bottom: 5px;

}
</style>