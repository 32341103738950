<template>
  <video autoplay muted loop playsinline id="myVideo">
    <source :src="getVideoAsset" type="video/mp4">
  </video>
</template>

<script>
export default {
  name: "BackgroundVideo",
  setup() {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    getVideoAsset() {
      if (this.windowHeight > this.windowWidth) {
        return require('@/assets/background_mobile.mp4')
      } else {
        return require('@/assets/background_desktop.mp4')
      }
    }
  }
}
</script>

<style>
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  z-index: -100;
  /*object-fit: cover;*/
}
</style>