<template>
  <div class="container align-content-center">
    <div class="row" :class="{'half-height': isEven(index)}" v-for="(productRow, index) in productGrid">
      <div class="col img-wrapper" v-for="product in productRow">
        <div @click="handle(product)">
          <img class="image cursor-pointer" :src="product.images[0]"/>
          <div class="cursor-pointer">{{product.name}}</div>
          <div v-if="enableRow">
            <slot  v-bind:product="product"></slot>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductGrid",
  props: {
    products: Array,
    clickHandler: Function,
    enableRow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isEven(index){
      return index % 2 === 0
    },
    handle(product){
      if (this.clickHandler) {
        this.clickHandler(product)
      }
    }
  },
  computed: {
    productGrid(){
      let result = [];
      let increment = 2;
      let index = 0;
      while (index < this.products.length) {
        let row = []
        for (let j = 0; j < increment; j++){
          if (index < this.products.length) {
            row.push(this.products[index++])
          }
        }
        result.push(row)
        if (increment === 1) increment = 2
        else increment = 1
      }
      return result;
    }
  }
}
</script>

<style scoped>
.img-wrapper {
  padding: 0.8rem;
}
.image {
  max-width: 100%;
  max-height: 15rem;;
}

.cursor-pointer {
  cursor: pointer;
}
</style>