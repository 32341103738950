import { createWebHashHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Harnasses from "@/views/Harnasses";
import Harnas from "@/views/Harnas";
import WhoIsHenry from "@/views/WhoIsHenry";
import HowToOrder from "@/views/HowToOrder";
import CollarCufs from "../views/CollarCufs";
import Product from "../views/Product";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/harnesses",
        name: "Harnesses",
        component: Harnasses,
    },
    {
        path: "/harnas/:name",
        name: "Harnas",
        component: Harnas,
    },
    {
        path: "/cuffs_n_collars",
        name: "Cuffs and Collars",
        component: CollarCufs
    },
    {
        path: "/cuffs/:name",
        name: "Cuff or Collar",
        component: Product
    },
    {
        path: "/who_is_henry",
        name: "WhoIsHenry",
        component: WhoIsHenry
    },
    {
        path: "/order",
        name: "HowToOrder",
        component: HowToOrder
    }
];

const router = createRouter({
    // history: createWebHistory(),
    history: createWebHashHistory(),
    routes,
    // mode: "hash"
});

export default router;