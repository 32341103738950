<template>
  <div>
    <BackgroundVideo></BackgroundVideo>
  </div>
</template>

<script>

import BackgroundVideo from "@/components/BackgroundVideo";

export default {
  name: "Home",
  components: {
    BackgroundVideo
  }
}
</script>

<style >

a {
  color: inherit;
  text-decoration: none;
}

.top-spacer {
  padding-top: 20%
}
</style>