<template>
<div class="who-is-henry">
  <h1 class="pb-4">Who the hell is Henry?</h1>
  <p>Oh! Henry was established in December of 2021 after a series of unfortunate events that occurred in the fall of 2021. Carrie Fisher once said, "Take your broken heart and turn it into art" . Art has always been there for me in times of need throughout my entire life.  It is my life line, the longest relationship with anything I've ever had in my entire life.  This project is a sum of all my parts. It is a love letter to my hometown, my family, my friends and, and most importantly my late grandfather Henry. It involves everything I have ever been influenced by and the most important people in my life. </p>
  <br>
  <p>
    Each harness is named after a family member, from my grandparents, mother uncles, and Great grandmother and the accessories are named after each of my cousins.
  </p>
  <br>
  <br>
  <p>
    Endless gratitude to those involved in each step of this project.
  </p>
  <br>
  <p>
    Harness Photos before edits by Georgiansparks <br>
    Lighting by Max Stroucken <br>
    Edits, photos, Art direction, Webdesign Nikkikaren Evans <br>
    Web development- Dries Meerman <br>
  </p>
  <br>
  <p>
    Models <br>
    Cacharel Verwey <br>
    Emma Wiersinga <br>
    Raphael Doorson <br>
    Harley Doorson <br>
    Sanne Berbers <br>
  </p>
</div>
</template>

<script>
export default {
name: "WhoIsHenry"
}
</script>

<style scoped>
  .who-is-henry {
    padding: 2rem;
  }
</style>