<template>
  <div class="how-to-order">
    Would you like to place an order?<br>
    Please email your order to <a href="mailto:ohhenryleathers@gmail.com">Ohhenryleathers@gmail.com</a> <br>
    Currently accepting PayPal, bank transfer and tikkie.<br>
    We will need your measurements! <br>
    Under bust /hips / Waist / Shoulder to navel <br>
    Have a custom design or color in mind?<br>
    Inquire with your exact idea/sketch.<br>
    <br>
    In Amsterdam? Pickup and fitting in house is possible.<br>
    <br>
    Any other questions, inquire above! <br>
  </div>
</template>

<script>
export default {
  name: "HowToOrder"
}
</script>

<style scoped>
  .how-to-order {
    padding: 2rem;
  }
</style>