

const harnesses = [
     {
        name: "The Henry",
        link: "https://store.ohhenryleather.com/The-Henry-p459657721",
        images: [
            require('./assets/harnes/1. Henry/Main.jpg'),
            require('./assets/harnes/1. Henry/1.png'),
            require('./assets/harnes/1. Henry/2.png'),
            require('./assets/harnes/1. Henry/3.png'),
        ],
        text: `This is Henry. The main staple, and our only interchangeable piece. Henry is incredibly strong, reliable, and dependable, the "Go-To" guy. He's everyone's favorite, helps you in your time of need, he's adaptable, and will soon be your main go to piece. The straps and belt, can easily be adjusted and  switched out to suit your mood, we offer a variety of belt and strap options,  each are sold separately.`
    },
    {
        name: 'Geri',
        link: "https://store.ohhenryleather.com/The-Geri-Vegetable-tanned-leather-p459683171",
        images: [
            require('./assets/harnes/2. Geri/Main.jpg'),
            require('./assets/harnes/2. Geri/1.png'),
            require('./assets/harnes/2. Geri/2.png'),
            require('./assets/harnes/2. Geri/3.jpg'),
        ],
        text: `✧*。Meet Geri.✧*。 Sophisticated, classy, the one that leaves her mark. Geri is the Matriarch, made from the finest Italian Vegan, vegetable tanned  leather, straight from our local providers at Leatherlands.nl. She's a staple you won't want to be without and will always go back to. Geri is a legend, forever beautiful, she is always being talked about.`
    },
     {
        name: 'Mary K',
        link: "https://store.ohhenryleather.com/The-Mary-k-p459692910",
        images: [
            require('./assets/harnes/3. Mary K/Main.png'),
            require('./assets/harnes/3. Mary K/1.png'),
            require('./assets/harnes/3. Mary K/2.png'),
            require('./assets/harnes/3. Mary K/3.png'),
        ],
        text: `Meet Mary K. An old soul with a Celtic heart. Mary K is the chosen one, she is strong, stable and loves hard. Staying true to your core values holds dear with her.  Guarded, and just a little bit scrappy, you're always protected with good energy, when  wearing Mary K, you will always shine.`
    },
    {
        name: 'Michael',
        link: "https://store.ohhenryleather.com/The-Michael-p459694767",
        images: [
            require('./assets/harnes/4. Michael/main.jpg'),
            require('./assets/harnes/4. Michael/1.jpeg'),
            require('./assets/harnes/4. Michael/2.jpeg'),
            require('./assets/harnes/4. Michael/3.jpeg'),
        ],
        text: `Earthy, The dreamer. Believes magic is possible and awaits you around every corner. This harness is full of energy, but you decide which kind. The elder filled with light wisdom.`
        // Goes well with the Julia cuff.
        // With Antique Brass hardware.
        // Paired best with : Lobster claw chain in Antique Brass look (sold separately).
    },
     {
        name: 'Johnny',
        link: "https://store.ohhenryleather.com/The-Johnny-p459699509",
        images: [
            require('./assets/harnes/5. Johnny/main.jpg'),
            require('./assets/harnes/5. Johnny/1.png'),
            require('./assets/harnes/5. Johnny/2.png'),
            require('./assets/harnes/5. Johnny/3.png'),
        ],
        text: `Sassy, sometimes highly controversial, loud but sensitive. Secretly everyone's favorite. Everyone always remembers Johnny for his loyalty and his presence.
        -Paired best with the Jo choker`
    },
    {
        name: 'Donna',
        link: "https://store.ohhenryleather.com/The-Donna-p459692726",
        images: [
            require('./assets/harnes/6. Donna/main.jpg'),
            require('./assets/harnes/6. Donna/1.jpg'),
            require('./assets/harnes/6. Donna/2.jpeg'),
            require('./assets/harnes/6. Donna/3.jpeg'),
        ],
        text: `Her namesake is strong, named after one of the hardest working, selfless, kind, funny,outgoing, inspiring women I've ever met. Nothing feels impossible when wearing the Donna, you will feel wrapped in love, warmth, and empathy. Wear this and you will do good, you will go far, you will most certainly help others. When wearing the Donna, people will hold you in high regard. The Donna is sacred.`
    },
    {
        name: 'Bobby',
        link: "https://store.ohhenryleather.com/The-Bobby-p459696599",
        images: [
            require('./assets/harnes/7. Bobby/main.jpg'),
            require('./assets/harnes/7. Bobby/1.png'),
            require('./assets/harnes/7. Bobby/2.png'),
            require('./assets/harnes/7. Bobby/3.png'),
        ],
        text: `The sweetest bad boy you ever laid eyes on. Made from fine Italian vegan leather The trouble maker that always keeps you on your toes and always keeps you coming back for more. Bobby is wildly funny, an actual genuis, wear this to summon all entrpunerinal enjenity within.`
    },
    {
        name: 'Joey',
        link: "https://store.ohhenryleather.com/The-Joey-p459702460",
        images: [
            require('./assets/harnes/8. Joey/main.png'),
            require('./assets/harnes/8. Joey/1.png'),
            require('./assets/harnes/8. Joey/2.png'),
            require('./assets/harnes/8. Joey/3.png'),
            ],
        text: `Buckle up, it's going to be one hell of a ride. You like Fast cars, fast times, being on the go? Then Joey is just the ticket for you. Strong and reliable, tough but sweet, some may think a bit rigid, but they just don't really know our Joe. `
    },
    {
        name: 'Lucy',
        link: "https://store.ohhenryleather.com/The-Lucy-p459699747",
        images: [
            require('./assets/harnes/10. Lucy/main.png'),
            require('./assets/harnes/10. Lucy/1.png'),
            require('./assets/harnes/10. Lucy/2.png'),
            require('./assets/harnes/10. Lucy/3.png'),
            ],
        text: `Lucy is the Matriarch, the reason why we're all here and the true pearl. Wear Lucy and you will ooze class and be honoured.`
    },
    {
        name: 'Victor',
        link: "https://store.ohhenryleather.com/The-Victor-Vegan-Leather-p459708058",
        images: [
            require('./assets/harnes/11. Victor/main.jpg'),
            require('./assets/harnes/11. Victor/1.png'),
            require('./assets/harnes/11. Victor/2.png'),
            require('./assets/harnes/11. Victor/3.png'),
            ],
        text: `The Victor, Victor is sturdy, and sophisticated. He is hard and soft at the same time. Victor is duality, conscious and aware, steady and Stable.`
    }
    ]

const cuffs = [
    {
        name: 'The Nikki',
        images: [ require('./assets/collar/The Nikki spike choker 35.jpg')],
        text: ''
    },
    {
        name: 'The Gia',
        images: [ require('./assets/collar/The Gia main 30 euros.jpg')],
        text: ''
    },
    {
        name: 'The Bella',
        images: [
            require('./assets/collar/Bella boot.png'),
            require('./assets/collar/Bella boot back.png'),
            require('./assets/collar/The bella boot harness 40 euro.png'),
        ],
        text: ''
    },
    {
        name: 'Cuffing season',
        images: [
            require('./assets/collar/It_s cuffing season alt.png'),
            require('./assets/collar/Cuffing season.png'),
            require('./assets/collar/Cuffing season top.png'),
            require('./assets/collar/It_s cuffing season with chain 50_.png'),

        ],
        text: ''
    },
    {
        name: 'All choked up',
        images: [
            require('./assets/collar/All choked up.png'),
            require('./assets/collar/I_m all choked up 35.png'),
        ],
        text: ''
    },
    {
        name: 'Thick Goldie',
        images: [
            require('./assets/collar/Thick Goldie 35 euros.png')
        ],
        text: ''
    },
    {
        name: 'The Jo',
        images: [ require('./assets/collar/The Jo choker 35.png')],
        text: ''
    },
    {
        name: 'The Julia',
        images: [ require('./assets/collar/The Julia cuff 40.png')],
        text: ''
    },
    {
        name: 'Antique Brass double chain with Japanese lobster claw chain',
        images: [
            require('./assets/collar/Antique Brass double chain with Japanese lobster claw chain 35.png'),
            require('./assets/collar/Antique Brass double chain with Japanese lobster claw chain 35(1).png'),
        ],
        text: ''
    },
    {
        name: 'Double gold chain with round lobster claw',
        images: [
            require('./assets/collar/Double gold chain with round lobster claw  25 euros.png'),
            require('./assets/collar/Double gold close up.png'),
        ],
        text: ''
    },
    {
        name: 'Gold single chain 60 cm with square lobster claw',
        images: [
            require('./assets/collar/Gold single chain 60 cm with square lobster claw 20 euros.png'),
        ],
        text: ''
    },
    {
        name: 'Silver single diamond cut chain 50 cm',
        images: [
            require('./assets/collar/Silver single diamond cut chain 50 cm 25 euros.png'),
            require('./assets/collar/Single silver.png'),
        ],
        text: ''
    },
]

function asDict(list, key) {
    return list.reduce( (total, current) => {
        total[current[key]] = current
        return total;
    }, {})
}

export default {
    harnesses: harnesses,
    cuffs: cuffs,
    asDict: asDict
}