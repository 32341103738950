<template>
  <div>
    <h1>{{this.name}}</h1>
    <div class="container">
      <div class="row">
        <div class="col pb-1">
          <img class="img-wrapper" :src="this.selectedImage()" />
        </div>
      </div>
      <div class="row m-3">
        <div class="col" v-for="i in this.imagesRange" @click="selectImage(i+1)">
          <img class="img-fluid small-img" :src="this.getDisplayImage(i+1)" :class="{'selected': i === this.selected}"/>
        </div>
      </div>
      <div class="row">
        <div class="col">
          {{this.product.price}}
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="description">{{product.text}}</p>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <a :href="product.link" class="purchase-link">Purchase</a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import Data from '../Data'

// const products =

let selected = 0;
export default {
  name: "Product",
  data() {
    return {
      selected: selected
  }
  },
  created() {
    this.name = this.$route.params.name;
    let category = "";
    if (this.$route.fullPath.indexOf('cuffs')) {
      category = "cuffs"
    }
    this.products = Data.asDict(Data[category], 'name')
  },
  computed: {
    product() {
      return this.products[this.name]
    },
    imagesRange(){
      return Array(this.product.images.length - 1).fill('').map( (n,i) => i)
    },
  },
  methods: {
    getDisplayImage(i){
      if (i === this.selected) {
        return this.product.images[0];
      }
      return this.product.images[i];
    },
    selectedImage(){
      return this.product.images[this.selected]
    },
    selectImage(i) {
      if (this.selected === i) this.selected = 0
      else this.selected = i;
    }
  }
}
</script>

<style scoped>
.description {
  padding: 1rem;
}

.img-wrapper {
  max-width: 86%;
  height: auto;
}

@media (min-width: 500px) {
  .img-wrapper {
    max-width: 30%;
  }

  .small-img {
    max-width: 40%;
  }
}
    .purchase-link {
      color: #4cd3ff;
      font-size: large;
      text-decoration: underline;
    }
</style>