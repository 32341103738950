<template>
<div class="harnas">
  <h1 class="pt-1">Collars &amp; Cuffs &amp; Chains</h1>
  <ProductGrid :products="cuffs" :enableRow="true" :clickHandler="openCuff" v-slot="slotProps">
  </ProductGrid>
</div>

</template>

<script>

import ProductGrid from "@/components/ProductGrid";

import products from '../Data'

export default {
  name: "CuffsnCollars",
  components: {
    ProductGrid
  },
  methods: {
    isEven(index){
      return index % 2 === 0
    },
    openCuff(product) {
      this.$router.push(`/cuffs/${product.name}`)
    },
  },
  setup() {

    return {
      cuffs: products.cuffs
    }
  },
}


</script>

<style scoped>
a {
  color: inherit;
  text-decoration: none;
}

@media (min-width: 600px) and (orientation: landscape) {
  .bg-overlay {
    left: 50%;
    transform: translateX(-25%);
  }
}

</style>