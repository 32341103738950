<template>
  <Menu></Menu>

  <router-view />
</template>

<script>
import Menu from './components/Menu'
import BackgroundVideo from "@/components/BackgroundVideo";


export default {
  name: 'App',
  components: {
    Menu,
    BackgroundVideo
  }
}
</script>

<style >


@font-face {
  font-family: 'impact_label_reverse';
  src: local('impact_label_reverse'),
  url(./assets/impactlabelreversed_regular_macroman/Impact_Label_Reversed-webfont.woff) format('woff')
}

body {
  background-color:  black !important;
  height: 100%;
}

#app {
  font-family: 'impact_label_reverse';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  
}

</style>
